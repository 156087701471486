import React from 'react'
import { FaRegSadCry } from "react-icons/fa";


const categoryOutDtc = () => {
  return (
    <h3 className="empty-category d-flex-center-all-column mt-4 mb-5">
      Category without DTC
      <FaRegSadCry className="mt-3" />
    </h3>
  )
}

export default categoryOutDtc