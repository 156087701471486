import React, { useState, useEffect } from "react"
import { Stack } from "react-bootstrap"
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import '../../styles/index.scss'
import Seo from '../../../components/seo/seo'
import CategoryOutDtc from '../../../components/category-out-dtc/category-out-dtc'
import NotFoundPage from '../../404';
import Dtc from '../../../components/dtc/dtc'
import { useMoralis } from "react-moralis";
import RingLoader from "react-spinners/RingLoader";
import { useDTCs } from '../../../context/DTCsContext';

const Category = ({ pageContext }) => {

    const data = pageContext.data;

    const options = [
        { value: '0', label: 'Popularity', bool: false, sortBy: 'order' },
        { value: '1', label: 'Last Updated', bool: false, sortBy: 'createdAt' },
        { value: '2', label: 'Higher to lower price', bool: false, sortBy: 'price' },
        { value: '3', label: 'Lower to higher price', bool: true, sortBy: 'price' },
        { value: '4', label: 'First Updated', bool: true, sortBy: 'createdAt' },
    ]

    const { isInitialized } = useMoralis();
    const { get_dtcs, get_favs } = useDTCs();

    const [initialLoading, setInitialLoading] = useState(true);
    const [getDtcs, setGetDtcs] = useState([]);
    const [arrayCollection, setArrayCollection] = useState([]);
    const [isFilterCollection, setIsFilterCollection] = useState(false);
    const [selectedCollection, setSelectedCollection] = useState({});
    const [selectedOption, setSelectedOption] = useState(options[0].value);

    useEffect(() => {
        getCollections()
            .then((response) => getDtcsInitial(response))
        isCollectionSelected();
    }, [isInitialized]);

    useEffect(() => {
        isCollectionSelected();
        getDtcsFilters();
    }, [selectedOption, selectedCollection]);

    const getCollections = async () => {
        const array = [];
        data?.collections?.forEach((arrayCollections) => {
            array.push(arrayCollections.contractAddress.toLowerCase())
        })
        setArrayCollection(array)
        return array
    }

    const getDtcsInitial = async (_array) => {
        try {
            if (_array.length === 0) {
                setInitialLoading(false)
                return
            }
            const response = await get_dtcs({ _collectionAddress: _array[0], _state: [2, 3], _sortBy: 'order', _asc: false });

            const favs = await get_favs({ _collectionAddress: _array, _states: [2, 3] });

            await getPopularDtc(favs, response);

            setInitialLoading(false)

        } catch (error) {
            console.error('error getting the initial dtcs', error);
        }
    }

    const getDtcsFilters = async () => {
        if (JSON.stringify(selectedCollection) === '{}') {
            if (arrayCollection.length === 1) {
                const allDtcs = await get_dtcs({ _collectionAddress: arrayCollection[0], _state: [2, 3], _sortBy: options[selectedOption].sortBy, _asc: options[selectedOption].bool })

                if (options[selectedOption].label === 'Popularity') {
                    get_favs({ _collectionAddress: arrayCollection[0], _states: [2, 3], _sortBy: 'order' }).then((response) => {
                        getPopularDtc(response, allDtcs);
                    })
                } else setGetDtcs(allDtcs)
            }
            else {
                const allDtcs = await get_dtcs({ _collectionAddress: [arrayCollection], _state: [2, 3], _sortBy: options[selectedOption].sortBy, _asc: options[selectedOption].bool })

                if (options[selectedOption].label === 'Popularity') {
                    get_favs({ _collectionAddress: [arrayCollection], _states: [2, 3], _sortBy: 'order' }).then((response) => {
                        getPopularDtc(response, allDtcs);
                    })
                } else setGetDtcs(allDtcs);
            }
        }
        else {
            const allDtcs = await get_dtcs({ _collectionAddress: selectedCollection.contractAddress, _state: [2, 3], _sortBy: options[selectedOption].sortBy, _asc: options[selectedOption].bool })

            if (options[selectedOption].label === 'Popularity') {
                console.log("in popularity 2")
                console.log(selectedCollection.contractAddress)
                get_favs({ _collectionAddress: selectedCollection.contractAddress, _states: [2, 3] }).then((response) => {
                    getPopularDtc(response, allDtcs);
                })
            } else setGetDtcs(allDtcs)
        }
    };

    const isCollectionSelected = async () => {
        if (JSON.stringify(selectedCollection) === '{}') {
            setIsFilterCollection(false);
        }
        else {
            setIsFilterCollection(true);
        }
    };

    const getPopularDtc = async (responseFavs, allDtcs) => {
        if (responseFavs && allDtcs) {
            let dtcFavoritesPlusDtcs = []
            console.log(responseFavs)
            dtcFavoritesPlusDtcs = [...responseFavs, ...allDtcs]
            const unique = [...new Map(dtcFavoritesPlusDtcs.map(item => [item["id"], item])).values()]
            setGetDtcs(unique)
        }
    };

    const handleChange = event => {
        setSelectedOption(event.target.value);
    };


    if (!data || data === []) {
        return (<NotFoundPage />)
    }
    else {
        return (
            <>
                <div className="background-theme">
                    <Container className="pt-5 category">
                        <h1>{data.name}</h1>
                        <Stack className="card-long">
                            {!isFilterCollection ?
                                (
                                    <img alt="card-long-first" src={data.bannerImage?.url} />
                                )
                                :
                                (
                                    <img alt="card-long-first" src={selectedCollection.bannerImage?.url} />
                                )
                            }
                        </Stack>
                        <Stack className="d-flex-sameline-space">
                            <Card className="mt-3 card-buttons">
                                <Card.Header>
                                    <Nav variant="tabs" defaultActiveKey="#all">
                                        <Nav.Item>
                                            <Nav.Link
                                                onClick={() => {
                                                    setSelectedCollection({});
                                                    setSelectedOption(0);
                                                }}
                                                href="#all">All Card Collection</Nav.Link>
                                        </Nav.Item>
                                        {
                                            data?.collections?.map((collection, index) =>
                                                <Nav.Item key={index}>
                                                    <Nav.Link
                                                        onClick={() => {
                                                            setSelectedCollection(collection);
                                                            setSelectedOption(0);
                                                        }}
                                                        href={"#" + collection.slug}>{collection.name}
                                                        <img alt="icon-collection" src={collection.coverImage.url} />
                                                    </Nav.Link>
                                                </Nav.Item>
                                            )
                                        }
                                    </Nav>
                                </Card.Header>
                            </Card>
                            <div className="select">
                                Order by:
                                <select value={selectedOption} onChange={handleChange}>
                                    {options.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </Stack>
                        {!isFilterCollection ?
                            (
                                <p dangerouslySetInnerHTML={{ __html: data.description }} className="mt-2 mb-4 description-category"></p>
                            )
                            :
                            (
                                <p dangerouslySetInnerHTML={{ __html: selectedCollection.description }} className="mt-2 mb-4 description-category"></p>
                            )
                        }
                        <Row>
                            <Col xs="12">
                                <h5 className="title-700">({getDtcs?.length}) Trading Cards</h5>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            {data?.collections?.length === 0 ?
                                (<CategoryOutDtc />)
                                :
                                ((initialLoading || !getDtcs)
                                    ? <Container className="loader">
                                        <RingLoader color="#2a4379" />
                                    </Container>
                                    : (
                                        getDtcs?.map((dtc, index) =>
                                            <Col className="mb-3" xs={12} sm={6} md={4} xl={3} key={index}>
                                                <Dtc src={dtc.attributes.image} name={dtc.attributes.name} collection={dtc.attributes.collection} price={dtc.attributes.price} sendTo={"/dtc-detail/" + dtc.id} />
                                            </Col>)
                                    )
                                )
                            }
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default Category;

export const Head = ({ pageContext }) => (

    <>
        <Seo title={pageContext.data?.name}
            description="Collect • Play • Trade"
            image="https://www.datocms-assets.com/80384/1664752762-dtc-logo-2021.png?auto=format"
        />
    </>
)